<template>
  <v-layout row wrap px-4 ml-5>
    <div class="w-100">
      <v-spacer />

      <!-- table -->
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        item-key="id"
        fixed-header
        height="calc(100vh - 200px)"
        :items-per-page="-1"
        hide-footer
      >
        <template v-slot:header.name="{ header }">
          <span class="px-3">
            {{ header.text }}
          </span>
        </template>
        <template v-slot:header.action="{ header }">
          <span class="px-3">
            {{ header.text }}
          </span>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.code }}
            </td>
            <td>
              <v-layout row class="ma-0 px-3">
                <div
                  v-if="item.organization_id"
                  v-tooltip="'名前を変更'"
                  class="has-link"
                  style="
                    color: rgb(70, 70, 90) !important;
                    font-weight: 600 !important;
                  "
                  @click.stop="$emit('click:editname', item)"
                >
                  {{ item.name }}
                </div>
                <div v-else>
                  {{ item.name }}
                </div>
                <v-spacer />
                <s-chip
                  v-if="!item.organization_id"
                  color="green-500"
                  class="px-2"
                >
                  デフォルト
                </s-chip>
              </v-layout>
            </td>
            <td>
              <v-layout row class="ma-0 px-3">
                <v-spacer />
                <span @click="$emit('click:detail', item)">
                  <s-icon tooltip="詳細" class="tw-mr-3" icon="feather-edit" />
                </span>
                <span @click="$emit('click:copy', item)">
                  <s-icon
                    tooltip="コピー"
                    class="tw-mr-3"
                    icon="feather-copy"
                  />
                </span>
                <span @click="$emit('click:delete', item)">
                  <s-icon
                    tooltip="削除"
                    :disabled="!(item.organization_id || admin)"
                    icon="feather-trash-2"
                  />
                </span>
              </v-layout>
            </td>
          </tr>
        </template>

        <v-alert slot="no-results" :value="true" color="info" icon="warning">
          {{ $t('message.noResult') }}
        </v-alert>
      </v-data-table>
    </div>
  </v-layout>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: '権限コード',
          value: 'code',
          sortable: false,
          width: '10%',
        },
        {
          text: '権限名',
          value: 'name',
          sortable: false,
          width: '70%',
        },
        {
          text: '',
          value: 'action',
          sortable: false,
          width: '20%',
        },
      ],
    }
  },
  mounted() {},
  methods: {},
}
</script>
