<template>
  <base-dialog
    ref="dialog"
    title="新しい権限を作成"
    message="権限名を入力してください。"
    :loading="loading"
    cancel-text="キャンセル"
    submit-text="作成"
    @submit="submit"
  >
    <template v-slot:card-content>
      <v-form ref="form" lazy-validation>
        <v-text-field
          v-model="form.name"
          placeholder="権限名"
          class="mb-4"
          :rules="[(v) => !!v || '必ず入力してください']"
          required
          dense
          outlined
          filled
        />
      </v-form>
    </template>
  </base-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        name: '',
      },
    }
  },
  methods: {
    open() {
      this.stopLoading()
      this.form.name = ''
      this.$refs.dialog.open()
    },
    close() {
      this.stopLoading()
      this.$refs.dialog.close()
    },
    startLoading() {
      this.loading = true
    },
    stopLoading() {
      this.loading = false
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.startLoading()
      this.$emit('submit', this.form)
    },
  },
}
</script>
