<template>
  <div class="w-100">
    <app-section-loader :status="loader" />

    <edit-role-name-dialog ref="editNameDialog" @submit="onSubmitEditName" />

    <create-role-dialog ref="createDialog" @submit="onSubmitCreate" />

    <copy-role-dialog ref="copyDialog" @submit="copyRole" />

    <delete-role-dialog ref="deleteDialog" @submit="onSubmitDelete" />
    <!-- buttons -->
    <v-toolbar flat height="60" class="w-100 mb-6 px-6">
      <span style="font-size: 17px"> 権限マスタ </span>
      <v-spacer />
      <div>
        <v-btn
          color="primary"
          dark
          depressed
          @click.native.stop="onClickCreate"
        >
          <i class="ti-plus mr-2" />
          新しい権限を作成
        </v-btn>
      </div>
    </v-toolbar>

    <!-- role table -->
    <role-table
      class="pr-12"
      :items="roles"
      :admin="user.is_support"
      @click:editname="onClickEditName"
      @click:detail="onClickDetail"
      @click:preview="onClickPreview"
      @click:delete="onClickDelete"
      @click:copy="onClickCopy"
    />
  </div>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import RoleTable from './components/RoleTable'
import CreateRoleDialog from './components/CreateRoleDialog'
import CopyRoleDialog from './components/CopyRoleDialog'
import EditRoleNameDialog from './components/EditRoleNameDialog'
import DeleteRoleDialog from './components/DeleteRoleDialog'

export default {
  components: {
    RoleTable,
    CreateRoleDialog,
    CopyRoleDialog,
    EditRoleNameDialog,
    DeleteRoleDialog,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loader: false,
      selected: null,
      roles: [
        {
          id: '',
          name: '',
          organization_id: '',
        },
      ],
    }
  },
  mounted() {
    this.getRoles()
  },
  methods: {
    onClickCreate() {
      this.$refs.createDialog.open()
    },
    onClickCopy(item) {
      this.selected = item
      this.$refs.copyDialog.open(item.name + ' コピー')
    },
    onClickDetail(item) {
      this.selected = item
      this.$router.push('/role/' + this.selected.id)
    },
    onClickEditName(item) {
      this.selected = item
      this.$refs.editNameDialog.open(item.name)
    },
    onClickPreview(item) {
      this.selected = item
    },
    onClickDelete(item) {
      this.selected = item
      this.$refs.deleteDialog.open()
    },
    onSubmitDelete() {
      const roleId = this.selected.id
      this.deleteRole(roleId)
    },
    onSubmitEditName(form) {
      const roleId = this.selected.id
      const name = form.name
      this.updateRoleName(roleId, name)
    },
    onSubmitCreate(form) {
      const name = form.name
      this.createRole(name)
    },
    createRole(name) {
      try {
        this.$store.dispatch('postNewRole', { name }).then((response) => {
          if (response.status === 'success') {
            this.$refs.createDialog.close()
            this.getRoles()
            Snackbar.success('作成しました。')
          } else {
            this.showError(response.error)
            this.$refs.createDialog.stopLoading()
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    copyRole(name) {
      try {
        const roleId = this.selected.id
        this.$store.dispatch('copyRole', { roleId, name }).then((response) => {
          if (response.status === 'success') {
            this.$refs.copyDialog.close()
            this.getRoles()
            Snackbar.success('コピーしました。')
          } else {
            this.showError(response.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getRoles() {
      try {
        this.$store.dispatch('getRoles').then((response) => {
          if (response.status === 'success') {
            this.roles = response.items
          } else {
            Snackbar.error('エラーが発生しました')
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    updateRoleName(roleId, name) {
      try {
        this.$store.dispatch('patchRole', { roleId, name }).then((response) => {
          this.$refs.editNameDialog.close()
          if (response.status === 'success') {
            Snackbar.success('更新しました。')
            this.selected.name = name
          } else {
            this.showError(response.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    deleteRole(roleId) {
      try {
        this.$store.dispatch('deleteRole', { roleId }).then((response) => {
          this.$refs.deleteDialog.close()
          if (response.status === 'success') {
            Snackbar.success('削除しました。')
            this.getRoles()
          } else if (response.error === 'in_use') {
            Snackbar.error('使用中の権限は削除できません')
          } else {
            Snackbar.error('エラーが発生しました')
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    showError(error) {
      switch (error) {
        case 'duplicate_name':
          Snackbar.error('既に登録済みの名称です')
          break
        default:
          Snackbar.error('エラーが発生しました')
          break
      }
    },
  },
}
</script>
