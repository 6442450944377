<template>
  <base-dialog
    ref="dialog"
    title="確認"
    message="この権限を削除しますか。"
    :loading="loading"
    cancel-text="いいえ"
    submit-text="はい"
    @submit="submit"
  />
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    open() {
      this.loading = false
      this.$refs.dialog.open()
    },
    close() {
      this.loading = false
      this.$refs.dialog.close()
    },
    submit() {
      this.loading = true
      this.$emit('submit')
    },
  },
}
</script>
